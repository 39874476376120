import React from 'react';
import _ from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import LocalizedLink from '@src/components/LocalizedLink';
import NewsletterForm from '@src/components/NewsletterForm';
import StreetmixLogo from '@src/assets/images/logo-horizontal-mono-white.svg';
import useTranslations from '@src/components/useTranslations';
import useSlugs from '@src/components/useSlugs';

import config from '../../../config.json';

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.light,
      padding: theme.spacing(5, 0, 5),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(10, 0),
      },
    },
    title: {
      color: theme.palette.primary.contrastText,
    },
    text: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '75%',
      },
    },
    divider: {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.25,
    },
    mobileDivider: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    linkList: {
      color: theme.palette.primary.light,
      listStyle: 'none',
      margin: theme.spacing(2, 0, 0),
      padding: 0,
      '& > li': {
        marginTop: theme.spacing(1),
      },
    },
    logo: {
      height: theme.spacing(1.5),
      margin: theme.spacing(0, 1),
      [theme.breakpoints.up('md')]: {
        height: theme.spacing(2),
      },
    },
    legal: {
      margin: theme.spacing(0, 0, 0, 2),
      color: theme.palette.primary.light,
    },
    mobilePush: {
      padding: 0,
      margin: 0,
      ...theme.mixins.toolbar,
      background: theme.palette.primary.main,
    },
    newsletterForm: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '75%',
        '& > *': {
          margin: theme.spacing(0, 2, 0, 0),
        },
        '& > button': {
          flex: '1 0 auto',
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
      },
    },
  }));

export default function Footer({ pageContext: { locale = 'en' } }) {
  const classes = useStyles()();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const {
    aboutSlug,
    contactSlug,
    changelogSlug,
    jobsSlug,
    newsSlug,
    // teamSlug,
  } = useSlugs();
  const {
    footerAbout,
    footerChangelog,
    footerCompany,
    footerContact,
    footerDocs,
    footerFaq,
    footerGuidebook,
    footerHeader,
    footerHelp,
    footerJobs,
    footerNews,
    footerOS,
    footerPrivacy,
    footerSignupCta,
    footerSocial,
    footerStore,
    footerTeam,
    footerText,
    footerTos,
  } = useTranslations();

  const data = useStaticQuery(graphql`
    query FooterQuery {
      privacy: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/site/pages/privacy-policy.md$/" }
        }
      ) {
        edges {
          node {
            fields {
              slugs {
                locale
                slug
              }
            }
            frontmatter {
              en {
                title
              }
              es {
                title
              }
            }
          }
        }
      }
      tos: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/site/pages/terms-of-service.md$/" }
        }
      ) {
        edges {
          node {
            fields {
              slugs {
                locale
                slug
              }
            }
            frontmatter {
              en {
                title
              }
              es {
                title
              }
            }
          }
        }
      }
    }
  `);

  const findSlug = (q) =>
    _.find(data[q].edges[0].node.fields.slugs, (o) => o.locale === locale).slug;

  const privacySlug = findSlug('privacy');
  const tosSlug = findSlug('tos');

  const companyLinks = [
    { id: 0, url: aboutSlug, text: footerAbout },
    // { id: 1, url: teamSlug, text: footerTeam },
    { id: 2, url: jobsSlug, text: footerJobs },
    { id: 3, url: newsSlug, text: footerNews },
    { id: 4, url: contactSlug, text: footerContact },
    { id: 5, url: 'https://cottonbureau.com/people/streetmix', text: footerStore }
  ];
  const docsLinks = [
    {
      id: 0,
      url: 'https://docs.streetmix.net/',
      text: footerHelp,
    },
    {
      id: 1,
      url: 'https://docs.streetmix.net/user-guide/support/faq',
      text: footerFaq,
    },
    {
      id: 2,
      url: 'https://docs.streetmix.net/user-guide/intro',
      text: footerGuidebook,
    },
    {
      id: 3,
      url: 'https://docs.streetmix.net/user-guide/changelog',
      text: footerChangelog
    },
    {
      id: 4,
      url: 'https://github.com/streetmix/streetmix',
      text: footerOS,
    },
  ];
  const socialLinks = config.elsewhere;

  return <>
    <footer className={classes.root}>
      <Container maxWidth={isMdUp ? 'lg' : isSmUp ? 'sm' : 'xs'}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography
              className={classes.title}
              color="inherit"
              component="h1"
              gutterBottom
              variant="h3"
            >
              {footerHeader}
            </Typography>
            <Typography
              className={classes.text}
              color="inherit"
              component="p"
              gutterBottom
              variant="body2"
            >
              {footerText}
            </Typography>
            <Typography
              className={classes.text}
              color="inherit"
              component="p"
              gutterBottom
              variant="body2"
            >
              {footerSignupCta}
            </Typography>
            <NewsletterForm className={classes.newsletterForm} />
          </Grid>
          <Grid item xs={12} className={classes.mobileDivider}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={4}>
              <Typography
                className={classes.title}
                color="inherit"
                display="block"
                gutterBottom
                noWrap
                variant="overline"
              >
                {footerCompany}
              </Typography>
              <ul className={classes.linkList}>
                {companyLinks.map(({ id, url, text }) => (
                  <li key={id}>
                    <Link
                      color="inherit"
                      component={LocalizedLink}
                      to={url}
                      variant="caption"
                    >
                      {text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classes.title}
                color="inherit"
                display="block"
                gutterBottom
                noWrap
                variant="overline"
              >
                {footerDocs}
              </Typography>
              <ul className={classes.linkList}>
                {docsLinks.map(({ id, url, text }) => (
                  <li key={id}>
                    <Link color="inherit" href={url} variant="caption">
                      {text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={4}>
              <Typography
                className={classes.title}
                color="inherit"
                display="block"
                gutterBottom
                noWrap
                variant="overline"
              >
                {footerSocial}
              </Typography>
              <ul className={classes.linkList}>
                {socialLinks.map(({ url, name }) => (
                  <li key={name}>
                    <Link color="inherit" href={url} variant="caption">
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid
            container
            direction="row-reverse"
            item
            justifyContent="space-between"
            xs={12}
          >
            <Grid item>
              <Link
                className={classes.legal}
                color="inherit"
                component={LocalizedLink}
                gutterBottom
                to={`/${tosSlug}`}
                variant="caption"
              >
                {footerTos}
              </Link>
              <Link
                className={classes.legal}
                color="inherit"
                component={LocalizedLink}
                gutterBottom
                to={`/${privacySlug}`}
                variant="caption"
              >
                {footerPrivacy}
              </Link>
            </Grid>
            <Grid item>
              <img
                src={StreetmixLogo}
                alt="Streetmix"
                className={classes.logo}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
    <Box
      className={classes.mobilePush}
      sx={{ display: { md: 'none', xs: 'block' } }}
    />
  </>;
}
