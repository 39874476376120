import React from 'react';
import _ from 'lodash';
import { Link as GatsbyLink } from 'gatsby';

import AppBar from '@mui/material/AppBar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import LocalizedLink from '@src/components/LocalizedLink';
import StreetmixLogo from '@src/assets/images/logo-horizontal.svg';
import useTranslations from '@src/components/useTranslations';

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      transition: `all ${theme.transitions.duration.standard}ms !important`,
    },
    push: {
      ...theme.mixins.toolbar,
    },
    container: {
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 10),
      },
    },
    grow: {
      flexGrow: 1,
    },
    logo: {
      height: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        height: theme.spacing(4.5),
      },
    },
    menu: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    menuItem: {
      listStyle: 'none',
      margin: theme.spacing(0, 2),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 2.5),
      },
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 4),
      },
    },
    menuLink: {
      ...theme.typography.button,
      padding: theme.spacing(1, 0),
      display: 'inline-block',
      borderBottom: `2px solid transparent`,
    },
    menuLinkActive: {
      borderColor: theme.palette.secondary.main,
    },
    divider: {
      borderColor: theme.palette.primary.light,
      margin: theme.spacing(0, 2),
      minHeight: '1.5em',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    languageSwitch: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    cta: {
      marginLeft: theme.spacing(2),
    },
  }));

export default function Topbar({ location, ...props }) {
  const classes = useStyles()();
  const trigger = useScrollTrigger();
  const { topbarCta } = useTranslations();

  const [raised, setRaised] = React.useState(false);

  const { i18n = [], locale = 'en', menu = [] } = props.pageContext;

  const menuItems = menu[locale];

  const activeSection = location.pathname.split('/')[locale === 'en' ? 1 : 2];

  const [translationsMenu, setTranslationsMenu] = React.useState(null);

  const menuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    keepMounted: true,
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    variant: 'menu',
  };

  const handleScroll = () => {
    if (window) setRaised(window.scrollY > 10 ? true : false);
  };

  React.useEffect(() => {
    if (window) {
      window.addEventListener('scroll', _.throttle(handleScroll, 350));
      return () => {
        window.removeEventListener('scroll', _.throttle(handleScroll, 350));
      };
    }
  }, []);

  return <>
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar
        className={classes.root}
        color="default"
        elevation={raised ? 1 : 0}
      >
        <Container className={classes.container} maxWidth={false}>
          <Toolbar disableGutters>
            <Typography variant="h6" component="h1">
              <LocalizedLink to="/">
                <img
                  src={StreetmixLogo}
                  alt="Streetmix"
                  className={classes.logo}
                />
              </LocalizedLink>
            </Typography>
            <div className={classes.grow} />
            <ul className={classes.menu}>
              {menuItems?.map(({ slug, title }) => {
                const is = () => {
                  if (!activeSection && slug.length === 0) {
                    return true;
                  } else if (
                    activeSection?.length > 0 &&
                    slug.includes(activeSection)
                  ) {
                    return slug === activeSection;
                  }
                };
                const isActive = is();
                {
                  /* const isActive = () => {
                  if (!activeSection && slug.length === 0) {
                    return slug;
                  } else if (
                    activeSection?.length > 0 &&
                    slug.includes(activeSection)
                  ) {
                    return slug === activeSection;
                  }
                }; */
                }

                return (
                  <li key={slug} className={classes.menuItem}>
                    <Link
                      className={`${classes.menuLink} ${
                        isActive ? classes.menuLinkActive : ''
                      }`}
                      color="textPrimary"
                      component={LocalizedLink}
                      to={`/${slug}`}
                      underline="none"
                    >
                      {title}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <Divider className={classes.divider} orientation="vertical" />
            <Button
              className={classes.languageSwitch}
              endIcon={<ArrowDropDownIcon />}
              onClick={(e) => setTranslationsMenu(e.target)}
            >
              {locale}
            </Button>
            <Button
              className={classes.cta}
              href="https://streetmix.net/"
              variant="outlined"
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              {topbarCta}
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
    </Slide>
    <div className={classes.push} />
    <Menu
      {...menuProps}
      anchorEl={translationsMenu}
      id="translations-actions"
      onClose={() => setTranslationsMenu(null)}
      open={Boolean(translationsMenu)}
    >
      {i18n.map((o, i) => (
        <MenuItem
          component={GatsbyLink}
          dense
          key={`${o.slug}${i}`}
          onClick={() => setTranslationsMenu(null)}
          selected={locale === o.locale}
          to={`${o.locale === 'en' ? `` : `/${o.locale}`}/${o.slug}`}
        >
          {o.locale.toUpperCase()}
        </MenuItem>
      ))}
    </Menu>
  </>;
}
