import React from 'react';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import AboutIcon from '@src/icons/AboutIcon';
import ContactIcon from '@src/icons/ContactIcon';
import LocalizedLink from '@src/components/LocalizedLink';
import PartnersIcon from '@src/icons/PartnersIcon';
import StoriesIcon from '@src/icons/StoriesIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 0,
    boxShadow: theme.shadows[1],
    height: 'auto',
    left: 0,
    margin: 0,
    position: 'fixed',
    right: 0,
    top: 'auto',
    transition: `all ${theme.transitions.duration.standard}ms !important`,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  action: {
    color: theme.palette.secondary.main,
  },
  selectedAction: {
    color: theme.palette.text.primary,
    '& *': {
      color: theme.palette.text.primary,
    },
  },
}));

export default function Navbar({ location, ...props }) {
  const classes = useStyles();
  const trigger = useScrollTrigger();

  const { i18n = [], locale = 'en', menu = [] } = props.pageContext;
  const menuItems = menu[locale];

  const icons = {
    about: <AboutIcon />,
    contact: <ContactIcon />,
    partners: <PartnersIcon />,
    stories: <StoriesIcon />,
  };

  return (
    <Slide appear={false} direction="up" in={!trigger}>
      <BottomNavigation className={classes.root} component="nav" showLabels>
        {menuItems?.map(({ icon, slug, title }) => {
          return (
            <BottomNavigationAction
              classes={{
                root: classes.action,
                selected: classes.selectedAction,
              }}
              component={LocalizedLink}
              key={slug}
              label={title}
              icon={icons[icon]}
              to={`${locale === 'en' ? '' : `/${locale}`}/${slug}`}
              underline="none"
              value={slug}
            />
          );
        })}
      </BottomNavigation>
    </Slide>
  );
}
